import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { ViewportProvider } from "context/ViewportProvider";
import TipWater from "pages/tips/TipWater";
import TipWaste from "pages/tips/TipWaste";
import TipEnergy from "pages/tips/TipEnergy";
import TriviaWater from "pages/trivia/TriviaWater";
import TriviaWaste from "pages/trivia/TriviaWaste";
import TriviaEnergy from "pages/trivia/TriviaEnergy";
import GeneralTemplate from "pages/generalTemplate/GeneralTitleSubtitle";
import GeneralTitleSubtitleImage from "pages/generalTemplate/GeneralTitleSubtitleImage";
import GeneralTitleImage from "pages/generalTemplate/GeneralTitleImage";
import GeneralImage from "pages/generalTemplate/GeneralImage";
import GeneralTemplateRouter from "pages/generalTemplate/GeneralTemplateRouter";
// import ComparisonWater from "pages/comparison/ComparisonWater";
import TheQuad from "pages/quad/TheQuad";
import ComparisonUsageElectricity from "pages/comparison/ComparisonUsageElectricity";





function App() {
  return (

    
    <ViewportProvider>
      <div className="bg-black h-full">

        <Router>
          <Routes>

            <Route path="/" element={<TipWater />} />

            <Route path="/water-tips/" element={<TipWater />} />
            <Route path="/water-tips/:itemId" element={<TipWater />} />

            <Route path="/waste-tips" element={<TipWaste />} />
            <Route path="/waste-tips/:itemId" element={<TipWaste />} />

            <Route path="/energy-tips" element={<TipEnergy />} />
            <Route path="/energy-tips/:itemId" element={<TipEnergy />} />

            <Route path="/water-trivia" element={<TriviaWater />} />
            <Route path="/water-trivia/:itemId" element={<TriviaWater />} />

            <Route path="/waste-trivia" element={<TriviaWaste />} />
            <Route path="/waste-trivia/:itemId" element={<TriviaWaste />} />

            <Route path="/energy-trivia" element={<TriviaEnergy />} />
            <Route path="/energy-trivia/:itemId" element={<TriviaEnergy />} />

            <Route path="/general-title-subtitle" element={<GeneralTemplate />} />
            <Route path="/general-title-subtitle/:itemId" element={<GeneralTemplate />} />

            <Route path="/general-title-subtitle-image" element={<GeneralTitleSubtitleImage />} />
            <Route path="/general-title-subtitle-image/:itemId" element={<GeneralTitleSubtitleImage />} />

            <Route path="/general-title-image" element={<GeneralTitleImage />} />
            <Route path="/general-title-image/:itemId" element={<GeneralTitleImage />} />

            <Route path="/general-image" element={<GeneralImage />} />
            <Route path="/general-image/:itemId" element={<GeneralImage />} />

            <Route path="/general-title-subtitle" element={<GeneralTemplate />} />
            <Route path="/general-title-subtitle/:itemId" element={<GeneralTemplate />} />

            <Route path="/general-title-subtitle-image" element={<GeneralTitleSubtitleImage />} />
            <Route path="/general-title-subtitle-image/:itemId" element={<GeneralTitleSubtitleImage />} />

            <Route path="/general-title-image" element={<GeneralTitleImage />} />
            <Route path="/general-title-image/:itemId" element={<GeneralTitleImage />} />

            <Route path="/general-image" element={<GeneralImage />} />
            <Route path="/general-image/:itemId" element={<GeneralImage />} />

            <Route path="/general-router" element={<GeneralTemplateRouter />} />
            <Route path="/general-router/:itemId" element={<GeneralTemplateRouter />} />

            {/* <Route path="/comparison-water" element={<ComparisonWater />} />
            <Route path="/comparison-water/:itemId" element={<ComparisonWater />}/> */}

            <Route path="/esg_messaging" element={<TheQuad />} />
            <Route path="/esg_messaging/:itemId" element={<TheQuad />} />

            <Route path="/Energy_Comparison" element={<ComparisonUsageElectricity />} />
            <Route path="/Energy_Comparison/:itemId" element={<ComparisonUsageElectricity />} />
          </Routes>
        </Router>
      </div>
    </ViewportProvider>
  );
}

export default App;
