import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Tips from "components/Tips/Tips";
import API from "utils/service";
import { preloadImage, useLocalStorage } from "utils/functions";
import { TOPIC } from "utils/constants";

function TipWaste(props) {
  const { itemId } = useParams();
  const [items, setItems] = useLocalStorage("tip_waste_items", []);
  const [storedCurrentItemIndex, storeCurrentItemIndex] = useLocalStorage(
    "tip_waste_index",
    0
  );
  const [currentItemIndex] = useState(storedCurrentItemIndex + 1);
  const [currentItem, setCurrentItem] = useState({});

  useEffect(() => {
    async function run() {
      try {
        const items = await API.retrieveWasteTipsRecords(props.type);
        setItems(items.data);
      } catch (e) {
        console.error(e);
      }
    }

    run();

    const interval = setInterval(run, 5000);

    return () => clearInterval(interval);
  }, [props]);

  useEffect(() => {
    if (itemId) {
      const itemIndex = items.findIndex((item) => item.id == itemId);

      if (itemIndex !== -1) {
        setCurrentItem(items[itemIndex]);
      }
    } else {
      let i = currentItemIndex;

      if (!items[i]) {
        if (items.length && items[i % items.length]) {
          i = i % items.length;
        }
        if (items[0]) {
          i = 0;
        }
      }

      items.length && setCurrentItem(items[i]);

      storeCurrentItemIndex(i);
    }
  }, [currentItemIndex, items, itemId]);

  // preload image for further use
  useEffect(() => {
    if (currentItem["Tip_Image"]) {
      preloadImage(API.getImageUrl(currentItem["Tip_Image"]));
    }
  }, [currentItem]);

  return (
    <Tips
      type={TOPIC.waste}
      data={currentItem}
      image={API.getImageUrl(currentItem && currentItem.Tip_Image)}
    />
  );
}

export default TipWaste;
