import React, { useEffect, useState } from "react";

const ViewportContext = React.createContext();

const ViewportProvider = ({ children }) => {
  const [isPortrait, setIsPortrait] = useState(false); //landscape by default
  const [isContextLoaded, setIsContextLoaded] = useState(false); //landscape by default

  useEffect(() => {
    checkViewport();
  }, []);

  const checkViewport = () => {
    if (window.innerHeight > window.innerWidth) {
      setIsPortrait(true);
    }
    setIsContextLoaded(true);
  };

  return (
    <ViewportContext.Provider value={{ isPortrait }}>
      {isContextLoaded && children}
    </ViewportContext.Provider>
  );
};

export { ViewportContext, ViewportProvider };
