import { useEffect, useState } from 'react';
import API from 'utils/service';
import { preloadImage, useLocalStorage } from 'utils/functions';
import { useParams } from 'react-router-dom';
import ComparisonUsage from 'components/Comparison/ComparisonUsage';

const ComparisonUsageElectricity = (props) => {
  const { itemId } = useParams();
  const [items, setItems] = useLocalStorage(
    'Comparison_usage_electricity_items',
    []
  );
  const [storedCurrentItemIndex, storeCurrentItemIndex] = useLocalStorage(
    'Comparison_usage_electricity_index',
    0
  );
  const [currentItemIndex] = useState(storedCurrentItemIndex + 1);
  const [currentItem, setCurrentItem] = useState({});

  useEffect(() => {
    async function run() {
      try {
        const items = await API.retrieveEnergyComparisonRecords(props.type);
        setItems(items.data);
        if (items?.Image) {
          // preload image for further use
          preloadImage(API.getImageUrl(items?.Image));
        }
      } catch (e) {
        console.error(e);
      }
    }

    run();

    const interval = setInterval(run, 5000);

    return () => clearInterval(interval);
  }, [props]);

  useEffect(() => {
    if (itemId) {
      const itemIndex = items.findIndex((item) => item.id == itemId);

      if (itemIndex !== -1) {
        setCurrentItem(items[itemIndex]);
      }
    } else {
      let i = currentItemIndex;

      if (!items[i]) {
        if (items.length && items[i % items.length]) {
          i = i % items.length;
        }
        if (items[0]) {
          i = 0;
        }
      }

      items.length && setCurrentItem(items[i]);

      storeCurrentItemIndex(i);
    }
  }, [currentItemIndex, items, itemId]);

  return (
    <ComparisonUsage
      data={currentItem}
      image={API.getImageUrl(currentItem?.Image)}
    />
  );
};

export default ComparisonUsageElectricity;
