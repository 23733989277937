import React from "react";
import { ViewportContext } from "context/ViewportProvider";
import stylesPortrait from "./headerPT.module.css";
import stylesLandscape from "./headerLS.module.css";

const Header = ({ title, titleColor, isSlide }) => {
  const { isPortrait } = React.useContext(ViewportContext);
  const styles = isPortrait ? stylesPortrait : stylesLandscape;

  return (
    <div className={styles.header}>
      <h1
        className={`${styles.headerText} ${titleColor} ${
          isSlide ? styles.headerTextSlide : styles.headerTextReveal
        }`}
      >
        {title}
      </h1>
    </div>
  );
};

export default Header;
