import React, { useEffect, useState } from "react";
import Footer from "components/Footer/Footer";
import { ViewportContext } from "context/ViewportProvider";
import stylesPortrait from "./quadPT.module.css";
import stylesLandscape from "./quadLS.module.css";

const Quad = ({ data, images }) => {
  const [theme, setTheme] = useState("");
  const { isPortrait } = React.useContext(ViewportContext);
  const styles = isPortrait ? stylesPortrait : stylesLandscape;
  const themeType = "darkTheme";

  useEffect(() => {
    switch (themeType) {
      case "lightTheme":
        setTheme("lightTheme");
        break;
      case "darkTheme":
        setTheme("darkTheme");
        const root = document.documentElement;
        root.style.setProperty("background-color", "black");
        break;
    }
  }, []);

  const stringifyText = JSON.stringify(data.Description);
  const outputResult = stringifyText?.replace(
    /(^|\s)(QUAD)(\s|$)/gi,
    "<b> $2 </b>"
  );
  const boldedWord = outputResult?.replace(/[|"]/g, "");

  if (Object.keys(data).length !== 0) {
    return (
      <>
        <div
          className={
            theme === "lightTheme"
              ? styles.whiteBackground
              : styles.blackBackground
          }
        >
          <div className={styles.wrapper}>
            <div
              className={
                theme === "lightTheme"
                  ? styles.firstBoxLight
                  : styles.firstBoxDark
              }
            >
              <div className={styles.imageContainer}>
                <div className={styles.backgroungGradient} />
                <div className={styles.image}>
                  <div className={styles.imageReveal}>
                    <img
                      src={images.Head}
                      className={styles.headImage}
                      alt="Head Image"
                    />
                  </div>
                </div>
              </div>

              <div className={styles.textContainer}>
                <div className={styles.textSlide}>
                  <h2
                    className={
                      theme === "lightTheme"
                        ? styles.tipsTextLight
                        : styles.tipsTextDark
                    }
                    dangerouslySetInnerHTML={{ __html: boldedWord }}
                  />
                </div>
              </div>
              <div className={styles.logoContainer}>
                <img
                  src={images.Logo}
                  alt="Logo Image"
                  className={styles.logoImage}
                />
              </div>
            </div>
            <div
              className={
                theme === "lightTheme"
                  ? styles.secondBoxLight
                  : styles.secondBoxDark
              }
            >
              <div className={styles.bottomLogos}>
                <img
                  src={images.SponsorLogo1}
                  alt="Sponsor Forum Logo"
                  className={styles.sponsorLogo1}
                />
                <img
                  src={images.SponsorLogo2}
                  alt="Sponsor Suites Logo"
                  className={styles.sponsorLogo2}
                />
              </div>
            </div>
          </div>
          <Footer isSlideBottom />
        </div>
      </>
    );
  } else {
    return 0;
  }
};

export default Quad;
