import React, { useEffect, useState } from "react";
import { TOPIC } from "utils/constants";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import { ViewportContext } from "context/ViewportProvider";
import stylesPortrait from "./generalTitleSubtitleImagePT.module.css";
import stylesLandscape from "./generalTitleSubtitleImageLS.module.css";

const Tips = ({ type, data, image }) => {
  const [titleColor, setTitleColor] = useState("");
  const { isPortrait } = React.useContext(ViewportContext);
  const styles = isPortrait ? stylesPortrait : stylesLandscape;

  useEffect(() => {
    switch (type) {
      case TOPIC.generalTitleSubtitle:
        setTitleColor("text-white-generalTemplate");
        break;
    }
  }, []);

  if (Object.keys(data).length !== 0) {
    console.log(data);
    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.imageContainer}>
            <div
              className={styles.image}
              style={{
                objectFit: "cover",
                backgroundSize: "cover",
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
              }}
            />
            <div
              className={`${styles.imageReveal}`}
              style={{
                objectFit: "cover",
                backgroundSize: "cover",
                backgroundColor: "black",
                backgroundRepeat: "no-repeat",
              }}
            />
          </div>
          <div className={styles.textContainer}>
            <div
              style={
                isPortrait ? { width: window.innerWidth } : { width: "inherit" }
              }
              className={styles.textSlide}
            >
              {/* <Header
                title={data.Template_B_Title}
                titleColor={titleColor}
                isSlide
                className={styles.Header}
              /> */}
              <h1 className={styles.generalTitleSubtitleImageTextHeader}>
                {data.Template_B_Title}
              </h1>
              <h2 className={styles.generalTitleSubtitleImageText}>
                {data.Template_B_Subtitle}
              </h2>
              {/* <div className={styles.dash} /> */}
            </div>
          </div>
        </div>
        <Footer isSlideBottom />
      </>
    );
  } else {
    return 0;
  }
};

export default Tips;
