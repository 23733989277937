import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Quad from "components/Quad/Quad";
import API from "utils/service";
import { preloadImage, useLocalStorage } from "utils/functions";

function TheQuad(props) {
  const { itemId } = useParams();
  const [items, setItems] = useLocalStorage("the_quad_items", []);
  const [storedCurrentItemIndex, storeCurrentItemIndex] = useLocalStorage(
    "the_quad_items",
    0
  );
  const [currentItemIndex] = useState(storedCurrentItemIndex + 1);
  const [currentItem, setCurrentItem] = useState({});

  useEffect(() => {
    async function run() {
      try {
        const items = await API.retrieveTheQuadRecords(props.type);
        setItems(items.data);
      } catch (e) {
        console.error(e);
      }
    }

    run();

    const interval = setInterval(run, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (itemId) {
      const itemIndex = items.findIndex((item) => item.id == itemId);

      if (itemIndex !== -1) {
        setCurrentItem(items[itemIndex]);
      }
    } else {
      let i = currentItemIndex;

      if (!items[i]) {
        if (items.length && items[i % items.length]) {
          i = i % items.length;
        }
        if (items[0]) {
          i = 0;
        }
      }

      items.length && setCurrentItem(items[i]);

      storeCurrentItemIndex(i);
    }
  }, [currentItemIndex, items, itemId]);

  useEffect(() => {
    if (currentItem["Head_Image"]) {
      preloadImage(API.getImageUrl(currentItem["Head_Image"]));
    }
    if (currentItem["Logo"]) {
      preloadImage(API.getImageUrl(currentItem["Logo"]));
    }
    if (currentItem["Sponsor_Logo_1"]) {
      preloadImage(API.getImageUrl(currentItem["Sponsor_Logo_1"]));
    }
    if (currentItem["Sponsor_Logo_2"]) {
      preloadImage(API.getImageUrl(currentItem["Sponsor_Logo_2"]));
    }
  }, [currentItem]);

  return (
    <Quad
      data={currentItem}
      images={{
        Head: API.getImageUrl(currentItem && currentItem.Head_Image),
        Logo: API.getImageUrl(currentItem && currentItem.Logo),
        SponsorLogo1: API.getImageUrl(currentItem && currentItem.Sponsor_Logo_1),
        SponsorLogo2: API.getImageUrl( currentItem && currentItem.Sponsor_Logo_2),
      }}
    />
  );
}

export default TheQuad;
