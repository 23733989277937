import React, { useState, useEffect } from 'react';
import Footer from 'components/Footer/Footer';
import { ViewportContext } from 'context/ViewportProvider';
import stylesPortrait from './comparisonUsagePT.module.css';
import stylesLandscape from './comparisonUsageLS.module.css';
import ComparisonUsageBox from 'components/ComparisonBox/ComparisonUsageBox';
import ProgressBar from '@ramonak/react-progress-bar';
import faceScreaming from '../../assets/img/face-screaming-in-fear.png';
import partyingFace from '../../assets/img/partying-face.png';

const ComparisonUsage = ({ data, image }) => {
  const { isPortrait } = React.useContext(ViewportContext);
  const styles = isPortrait ? stylesPortrait : stylesLandscape;
  const [firstScreen, setFirstScreen] = useState(true);
  const [isAnimated, setIsAnimated] = useState(true);
  const [completed, setCompleted] = useState(0);

  let textColor = '';
  let faceGif = '';

  // useEffect(() => {
  //   setCompleted(0);
  //   setTimeout(() => {
  //     setFirstScreen(false);
  //   }, 15000);
  //   setProgressStatus();
  // }, [firstScreen, completed]);

  const setProgressStatus = () => {
    setCompleted(100);
    setIsAnimated(true);
    setTimeout(() => {
      setCompleted(0);
      setIsAnimated(false);
    }, 30000);
  };

  if (Object.keys(data).length !== 0) {
    const index = data?.Percentage_Description.search('VS');
    if (data.Usage_Arrow === 'Up') {
      textColor = 'status-red';
      faceGif = faceScreaming;
    } else if (data.Usage_Arrow === 'Down') {
      textColor = 'status-green';
      faceGif = partyingFace;
    }
    return (
      <>
        <div className={`${firstScreen && styles.imageSlide}`}>
          <img
            style={{
              objectFit: 'cover',
              backgroundSize: 'cover',
              backgroundImage: isPortrait ? `url(${image})` : `url(${image})`,
              backgroundRepeat: 'no-repeat',
              width: '100%',
            }}
            className={styles.backgroundImage}
            alt=''
            src={isPortrait ? image : image}
          />
        </div>
        <div className={styles.comparisonContainer}>
          <div className={styles.titleContainer}>
            <h1
              className={`${styles.title} ${
                firstScreen && styles.headerTextSlide
              }`}
            >
              ELECTRICITY USAGE
            </h1>
          </div>

          <div
            className={`${
              firstScreen &&
              !isPortrait &&
              'flex flex-row justify-between items-center'
            }`}
          >
            <h2
              className={`${styles.percentageDescription} ${
                !isPortrait && 'basis-1/2'
              } ${firstScreen && styles.firstDescription}`}
            >
              {isPortrait || firstScreen ? (
                <>
                  {data.Percentage_Description.slice(0, index)}
                  {data.Percentage_Description.slice(index)}
                </>
              ) : (
                data.Percentage_Description
              )}
              {':'}
            </h2>
            {firstScreen && <ComparisonUsageBox data={data} />}
          </div>
          {!firstScreen && (
            <>
              {isPortrait ? (
                <div
                  className={`${styles.padding} ${styles.secondDescription}`}
                >
                  <div className={styles.percentageDescription}>
                    {data.Sentiment_Description_Part_1}
                    <span className={`text-${textColor}`}>
                      {' '}
                      {data.Sentiment_Value}
                    </span>
                  </div>
                  <div className={styles.percentageDescription}>
                    {data.Sentiment_Description_Part_2}
                  </div>
                </div>
              ) : (
                <div
                  className={`${styles.percentageDescription} ${styles.secondDescription}`}
                >
                  {data.Sentiment_Description_Part_1}
                  <span className={`text-${textColor}`}>
                    {' '}
                    {data.Sentiment_Value}
                  </span>{' '}
                  {data.Sentiment_Description_Part_2}
                </div>
              )}
              <div
                className={`flex justify-end ${styles.emojiContainer} ${styles.emojiSlide}
                `}
              >
                <img
                  className={styles.emojiIcon}
                  src={`${faceGif}`}
                  alt=''
                ></img>
              </div>
            </>
          )}
        </div>
        <div
          className={`${styles.progressBarContainer} 
          ${styles.progressBarAnimation}
          ${firstScreen && styles.progressBarFirst} 
          ${!firstScreen && styles.progressBarSecond}`}
        >
          <ProgressBar
            completed={completed}
            baseBgColor={'bg-progress-bar'}
            bgColor='#66EC00'
            isLabelVisible={false}
            transitionDuration={'30s'}
            animateOnRender={isAnimated}
            className={styles.progressBar}
            borderRadius={'0px'}
          />
        </div>
        <div className='mx-32 mt-2'>
          <Footer isSlideBottom />
        </div>
      </>
    );
  } else {
    return 0;
  }
};

export default ComparisonUsage;
