import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Trivia from "components/Trivia/Trivia";
import API from "utils/service";
import { preloadImage, useLocalStorage } from "utils/functions";
import { TOPIC } from "utils/constants";

function TriviaWaste(props) {
  const { itemId } = useParams();
  const [items, setItems] = useLocalStorage("trivia_waste_items", []);
  const [storedCurrentItemIndex, storeCurrentItemIndex] = useLocalStorage(
    "trivia_waste_index",
    0
  );
  const [currentItemIndex] = useState(storedCurrentItemIndex + 1);
  const [currentItem, setCurrentItem] = useState({});

  useEffect(() => {
    async function run() {
      try {
        const items = await API.retrieveWasteTriviaRecords(props.type);
        setItems(items.data);
      } catch (e) {
        console.error(e);
      }
    }

    run();

    const interval = setInterval(run, 5000);

    return () => clearInterval(interval);
  }, [props]);

  useEffect(() => {
    if (itemId) {
      const itemIndex = items.findIndex((item) => item.id == itemId);

      if (itemIndex !== -1) {
        setCurrentItem(items[itemIndex]);
      }
    } else {
      let i = currentItemIndex;

      if (!items[i]) {
        if (items.length && items[i % items.length]) {
          i = i % items.length;
        }
        if (items[0]) {
          i = 0;
        }
      }

      items.length && setCurrentItem(items[i]);

      storeCurrentItemIndex(i);
    }
  }, [currentItemIndex, items, itemId]);

  return <Trivia type={TOPIC.waste} data={currentItem} />;
}

export default TriviaWaste;
