import React, { useEffect, useState } from "react";
import { TOPIC } from "utils/constants";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import { ViewportContext } from "context/ViewportProvider";
import stylesPortrait from "./generalImagePT.module.css";
import stylesLandscape from "./generalImageLS.module.css";

const GeneralImageMarkup = ({ type, data, image }) => {
  const [titleColor, setTitleColor] = useState("");
  const { isPortrait } = React.useContext(ViewportContext);
  const styles = isPortrait ? stylesPortrait : stylesLandscape;

  useEffect(() => {
    switch (type) {
      case TOPIC.generalTitleImage:
        setTitleColor("text-white-generalTemplate");
        break;
    }
  }, []);

  if (Object.keys(data).length !== 0) {
    console.log(data);
    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.imageContainer}>
            <img
              className={styles.image}
              style={{
                objectFit: "cover",
                backgroundSize: "cover",
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
              }}
              alt=""
              src={image}
              />
            </div>
          <div className={styles.textContainer}>
            <div
              style={
                isPortrait ? { width: window.innerWidth } : { width: "inherit" }
              }
              className={styles.textSlide}
            >
              {/* <Header
                title={data.Template_C_Title}
                titleColor={titleColor}
                isSlide
                className={styles.Header}
              /> */}
            </div>
          </div>
        </div>
        <Footer isSlideBottom />
      </>
    );
  } else {
    return 0;
  }
};

export default GeneralImageMarkup;
