const API = {
  retrieveEnergyTipsRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Energy_Tips/`).then(
      (r) => r.json()
    );
  },
  retrieveEnergyComparisonRecords(item) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/items/Energy_Comparison/`
    ).then((r) => r.json());
  },

  retrieveWaterTipsRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Water_Tips/`).then(
      (r) => r.json()
    );
  },
  retrieveWasteTipsRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Waste_Tips/`).then(
      (r) => r.json()
    );
  },
  retrieveEnergyTriviaRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Energy_Trivia/`).then(
      (r) => r.json()
    );
  },
  retrieveWaterTriviaRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Water_Trivia/`).then(
      (r) => r.json()
    );
  },
  retrieveWasteTriviaRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Waste_Trivia/`).then(
      (r) => r.json()
    );
  },
  retrieveWaterComparisonRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Comparison/`).then(
      (r) => r.json()
    );
  },
  retrieveFooterRecords() {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Footer/`).then((r) =>
      r.json()
    );
  },
  retrieveGlobalFooterRecords() {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Global_Footer/`).then(
      (r) => r.json()
    );
  },
  retrieveGeneralTemplateRecords() {
    return fetch(
      `${process.env.REACT_APP_API_URL}/items/General_Template/`
    ).then((r) => r.json());
  },
  retrieveWaterComparisonRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Comparison/`).then(
      (r) => r.json()
    );
  },
  retrieveWasteTipsRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Waste_Tips/`).then(
      (r) => r.json()
    );
  },
  retrieveWasteTriviaRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Waste_Trivia/`).then(
      (r) => r.json()
    );
  },

  getImageUrl(id) {
    return id ? `${process.env.REACT_APP_API_URL}/assets/${id}` : '';
  },

  retrieveTheQuadRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/ESG_Messaging/`).then(
      (r) => r.json()
    );
  },
};

export default API;
