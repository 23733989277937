import React, { useEffect, useState } from "react";
import { TOPIC } from "utils/constants";
import Header from "components/Header/Header";
import TriviaAnswer from "components/TriviaAnswer/TriviaAnswer";
import Footer from "components/Footer/Footer";
import { ViewportContext } from "context/ViewportProvider";
import stylesPortrait from "./triviaPT.module.css";
import stylesLandscape from "./triviaLS.module.css";

const Trivia = ({ type, data }) => {
  const [titleColor, setTitleColor] = useState("");
  const { isPortrait } = React.useContext(ViewportContext);
  const styles = isPortrait ? stylesPortrait : stylesLandscape;
  const landscapeDelay = !isPortrait && 0.3;

  useEffect(() => {
    switch (type) {
      case TOPIC.water:
        setTitleColor("text-blue-water");
        break;
      case TOPIC.waste:
        setTitleColor("text-green-waste");
        break;
      case TOPIC.energy:
        setTitleColor("text-orange-energy");
        break;
    }
  }, []);

  if (Object.keys(data).length !== 0) {
    return (
      <>
        <Header title={data.Title} titleColor={titleColor} />
        <div className={`${styles.questionCard} ${styles.questionCardSlide}`}>
          <h2 className={styles.questionText}>{data.Trivia_Question}</h2>
          <div className={styles.answers}>
            <TriviaAnswer
              letter="A"
              answer={data.Question_Choice_A}
              delay={1 + landscapeDelay}
              correctAnswer={data.Correct_Answer}
              isPortrait={isPortrait}
            />
            <TriviaAnswer
              letter="B"
              answer={data.Question_Choice_B}
              delay={1.3 + landscapeDelay}
              correctAnswer={data.Correct_Answer}
              isPortrait={isPortrait}
            />
            
            <TriviaAnswer
              letter="C"
              answer={data.Question_Choice_C}
              delay={1.6 + landscapeDelay}
              correctAnswer={data.Correct_Answer}
              isPortrait={isPortrait}
            />
            <TriviaAnswer
              letter="D"
              answer={data.Question_Choice_D}
              delay={1.9 + landscapeDelay}
              correctAnswer={data.Correct_Answer}
              isPortrait={isPortrait}
            />
          </div>
        </div>
        <Footer />
      </>
    );
  }
  else {
    return 0;
  }
};

export default Trivia;
