import React from 'react';
import { ViewportContext } from 'context/ViewportProvider';
import stylesPortrait from './comparisonUsageBoxPT.module.css';
import stylesLandscape from './comparisonUsageBoxLS.module.css';
import arrow from '../../assets/img/arrow.png';

const ComparisonUsageBox = ({ data }) => {
  const { isPortrait } = React.useContext(ViewportContext);
  const styles = isPortrait ? stylesPortrait : stylesLandscape;
  let borderColor = '';
  let rotate = '';
  let usagePercentage = '';

  if (Object.keys(data).length !== 0) {
    if (data.Usage_Arrow === 'Up') {
      borderColor = 'bg-border-red opacity-[0.22] border-border-red/[.22]';
      rotate = 'rotate-180';
      usagePercentage = 'bg-usage-red-percentage';
    } else if (data.Usage_Arrow === 'Down') {
      borderColor = 'bg-border-green opacity-[0.22] border-border-green/[.22]';
      rotate = 'rotate-0';
      usagePercentage = 'bg-usage-green-percentage';
    }
    return (
      <div
        className={`${styles.comparisonBoxContainer} ${usagePercentage}  ${
          styles.slideAnimation
        } ${!isPortrait && 'basis-1/2'}`}
      >
        <div className={styles.leftColumn}>
          <span className={styles.percentageText}>
            {data.Usage_Percentage}%
          </span>
        </div>

        <div className={`${borderColor} ${styles.border} `} />
        <div className={styles.rightColumn}>
          <h2 className={styles.titleText}>
            {data.Usage_Arrow === 'Up' ? 'INCREASE' : 'DECREASE'}
          </h2>
          <div className={styles.arrowContainer}>
            <img className={`${rotate}`} src={`${arrow}`} alt='' />
          </div>
        </div>
      </div>
    );
  } else {
    return 0;
  }
};

export default ComparisonUsageBox;
