import API from "utils/service";
import React, { useEffect, useState } from "react";
import { useLocalStorage } from "utils/functions";
import { useParams } from "react-router-dom";
import GeneralTitleSubtitle from "./GeneralTitleSubtitle";
import GeneralTitleSubtitleImage from "./GeneralTitleSubtitleImage";
import GeneralTitleImage from "./GeneralTitleImage";
import GeneralImage from "./GeneralImage";

function GeneralTemplateRouter(props) {
 const [visible, setVisible] = React.useState(false);
 const { itemId } = useParams();
 const [items, setItems] = useLocalStorage("general_template_items", []);
 const [storedCurrentItemIndex, storeCurrentItemIndex] = useLocalStorage(
   "general_image_index",
   0
 );
 const [currentItemIndex] = useState(storedCurrentItemIndex + 1);
 const [currentItem, setCurrentItem] = useState({});

 useEffect(() => {
   async function run() {
     try {
       const items = await API.retrieveGeneralTemplateRecords(props.type);
       setItems(items.data);
     } catch (e) {
       console.error(e);
     }
   }

   run();

   const interval = setInterval(run, 5000);

   return () => clearInterval(interval);
 }, [props]);

 useEffect(() => {
   if (itemId) {
     const itemIndex = items.findIndex((item) => item.id == itemId);

     if (itemIndex !== -1) {
       setCurrentItem(items[itemIndex]);
     }
   } else {
     let i = currentItemIndex;

     if (!items[i]) {
       if (items.length && items[i % items.length]) {
         i = i % items.length;
       }
       if (items[0]) {
         i = 0;
       }
     }

     items.length && setCurrentItem(items[i]);

     storeCurrentItemIndex(i);
   }
 }, [currentItemIndex, items, itemId]);

 return (
   
   <div>
     
   </div>
 );
};
export default GeneralTemplateRouter;
