import React, { useEffect, useState } from "react";
import { TOPIC } from "utils/constants";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import { ViewportContext } from "context/ViewportProvider";
import stylesPortrait from "./generalTitleSubtitlePT.module.css";
import stylesLandscape from "./generalTitleSubtitleLS.module.css";

const Tips = ({ type, data, image }) => {
  const [titleColor, setTitleColor] = useState("");
  const { isPortrait } = React.useContext(ViewportContext);
  const styles = isPortrait ? stylesPortrait : stylesLandscape;

  useEffect(() => {
    switch (type) {
      case TOPIC.generalTitleSubtitle:
        setTitleColor("text-white-generalTemplate");
        break;
    }
  }, []);

  if (Object.keys(data).length !== 0) {
    console.log(data)
    return (
      <>
        {/* <Header title={data.Template_A_Title} titleColor={titleColor} isSlide /> */}

        <div className={styles.wrapper}>
                   <div className={styles.textContainer}>
            <div
              style={
                isPortrait ? { width: window.innerWidth } : { width: "inherit" }
              }
              className={styles.textSlide}
            >
              <h1 className={styles.generalTitleSubtitleImageTextHeader}>{data.Template_A_Title}</h1>
              <h2 className={styles.generalTitleSubtitleImageText}>{data.Template_A_Subtitle}</h2>
              <div className={styles.dash} />
            </div>
          </div>
        </div>
        <Footer isSlideBottom />
      </>
    );
  }
  else {
    return 0;
  }
};

export default Tips;
