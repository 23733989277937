import stylesPortrait from "./triviaAnswerPT.module.css";
import stylesLandscape from "./triviaAnswerLS.module.css";

const TriviaAnswer = ({ letter, answer, delay, correctAnswer, isPortrait }) => {
  const styles = isPortrait ? stylesPortrait : stylesLandscape;

  return (
    <div
      className={`${styles.answerBox} ${
        letter === correctAnswer ? styles.animation : styles.animationFade
      } `}
      style={{
        animationDelay: `${delay}s`,
        animationDuration: `${10 - delay}s`,
      }}
    >
      <div className={styles.letterBox}>
        <p className={styles.letterText}>{letter}</p>
      </div>
      <h3 className={styles.answerText}>{answer}</h3>
    </div>
  );
};

export default TriviaAnswer;
